<template>
  <Render
    v-if="theme?.header"
    :config="theme.header"
    class="visible-mobile visible-tablet visible-laptop visible-desktop"
    :data="{ icon: 'circle-user', description: 'User Profile', type: 'user_profile' }"
  />
</template>

<script setup>
import { inject, watch } from 'vue';
import Render from '~/components/builder/Render.vue';
import Navbar from './Usernav.vue';
import { useSession } from '~/composables/useSession';
import { useRender } from '~/composables/useRender';

// Use render status
const { status } = useRender();

// Inject theme
const theme = inject('theme');

// Use session composable
const { sessionValid, refreshSession } = useSession();

// Watch for session changes
watch(sessionValid, (newVal) => {
  if (!newVal) {
    console.log('Session is invalid. Perform additional actions here.');
  } else {
    console.log('Session is valid.');
  }
});

// Optionally refresh session on mount
refreshSession();
</script>
