<template>
  <CookiePolicyPopup />
  <Render v-if="theme?.footer" :config="theme.footer" />
</template>

<script setup>
import { inject, onMounted } from 'vue';
import Render from '~/components/builder/Render.vue';
import CookiePolicyPopup from '~/components/plugin/CookiePolicyPopup.vue';

const theme = inject('theme');

// Dynamically add the Plausible script
onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://plausible.io/js/script.file-downloads.hash.outbound-links.pageview-props.revenue.tagged-events.js';
  script.defer = true;
  script.setAttribute('data-domain', window.location.hostname);
  document.head.appendChild(script);

  // Initialize the Plausible function
  window.plausible = window.plausible || function () {
    (window.plausible.q = window.plausible.q || []).push(arguments);
  };
});
</script>
