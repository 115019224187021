<template>
    <!-- Background overlay for dimming -->
    <div v-if="!isAccepted" class="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
  
    <!-- Initial popup at the bottom right -->
    <transition name="slide-up">
      <div v-if="!isAccepted && !showDetail" class="fixed bottom-0 right-0 m-4 bg-white p-4 border border-gray-200 w-80 z-50">
        <h3 class="text-lg font-bold mb-2"><span class="text-2xl mr-2">🍪</span> Cookie Policy</h3>
        <p class="mb-4 text-sm text-gray-700">
          We use cookies to enhance your experience. Please select your preferences.
        </p>
        <div class="flex justify-between space-x-2">
          <button @click="rejectAll" class="bg-gray-100 text-gray-700 text-xs px-3 py-1 border border-gray-300 rounded">Reject</button>
          <button @click="acceptAllCookies" class="bg-blue-500 text-white text-xs px-3 py-1 rounded">Accept</button>
          <button @click="showDetailPopup" class="bg-gray-200 text-gray-700 text-xs px-3 py-1 border border-gray-300 rounded">Show Details</button>
        </div>
      </div>
    </transition>
  
    <!-- Detailed modal popup in the center of the screen -->
    <div v-if="showDetail" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div @click.stop class="bg-white w-96 border border-gray-200 relative">
        <div class="border-b border-gray-300 ">
            <div class="p-3">
                <!-- Header with Cookie Icon -->
                <h3 class="text-lg font-bold"><span class="text-2xl mr-2">🍪</span> Cookie Policy</h3>
                <button @click="closeDetailPopup" class="absolute top-2 right-2 text-gray-500 text-2xl">✕</button>
            </div>
        </div>
  
        <!-- Tab Navigation -->
        <div class="flex border-b mb-4 text-sm">
          <button
            :class="{'border-b-2 border-blue-500': selectedTab === 'necessary'}"
            @click="selectedTab = 'necessary'"
            class="flex-1 py-2 text-center"
          >
            Necessary
          </button>
          <button
            :class="{'border-b-2 border-blue-500': selectedTab === 'analytics'}"
            @click="selectedTab = 'analytics'"
            class="flex-1 py-2 text-center"
          >
            Analytics
          </button>
          <button
            :class="{'border-b-2 border-blue-500': selectedTab === 'marketing'}"
            @click="selectedTab = 'marketing'"
            class="flex-1 py-2 text-center"
          >
            Marketing
          </button>
        </div>
  
        <!-- Tab Content -->
        <div class="mb-4 text-sm text-gray-700">
            <div class="p-3">
                <p v-if="selectedTab === 'necessary'">
                    Necessary cookies are required for the website to function and cannot be disabled.
                </p>
                <p v-if="selectedTab === 'analytics'">
                    Analytics cookies help us improve our website by collecting information about its usage.
                </p>
                <p v-if="selectedTab === 'marketing'">
                    Marketing cookies are used to track visitors across websites to serve relevant ads.
                </p>
            </div>
        </div>
  
        <!-- Accept/Reject buttons -->
        <div class="border-t border-gray-300 bg-gray-100">
            <div class="p-3 flex justify-between ">
                <button @click="rejectAll" class="bg-gray-100 text-gray-700 text-xs px-3 py-1 border border-gray-300 rounded">Reject All</button>
                <button @click="acceptAllCookies" class="bg-blue-500 text-white text-xs px-3 py-1 rounded">Accept All</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { useCookie } from '#app'
  import { ref, onMounted, onBeforeMount } from 'vue'
  
  // Initialize cookie consent-related states
  const isAccepted = ref(true)
  const showDetail = ref(false)
  const selectedTab = ref('necessary')
  const consent = ref({
    necessary: true, // Always true
    analytics: false,
    marketing: false
  })
  
  // Declare cookiePolicy variable (will be assigned in onBeforeMount)
  let cookiePolicy
  
  // Function to safely parse JSON
  const safeParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString)
    } catch (error) {
      console.error("Failed to parse JSON:", error)
      return null
    }
  }
  
  // Ensure cookies are accessed only on the client-side
  onBeforeMount(() => {
    if (process.client) {
      // Initialize cookie for client-side usage
      cookiePolicy = useCookie('_policy', { maxAge: 365 * 24 * 60 * 60 }) // 1-year expiration
    }
  })
  
  // Logic to handle cookie consent
  onMounted(() => {
    if (process.client) {
      let cookieValue = cookiePolicy.value ? decodeURIComponent(cookiePolicy.value) : null
  
      if (cookieValue && typeof cookieValue === 'string') {
        const savedPolicy = safeParseJSON(cookieValue)
  
        if (savedPolicy) {
          consent.value.analytics = savedPolicy.analytics || false
          consent.value.marketing = savedPolicy.marketing || false
          isAccepted.value = true // Hide the popup
        } else {
          cookiePolicy.value = null
        }
      } else {
        isAccepted.value = false // Show the popup
      }
    }
  })
  
  // Function to accept all cookies (Necessary, Analytics, Marketing)
  const acceptAllCookies = () => {
    const policyData = JSON.stringify({
      necessary: true, // Always true
      analytics: true,
      marketing: true
    })
  
    // Store the policy data in the cookie
    cookiePolicy.value = encodeURIComponent(policyData) // URL encode the value
  
    consent.value.analytics = true
    consent.value.marketing = true
  
    isAccepted.value = true
    showDetail.value = false
  }
  
  // Function to reject all but necessary cookies
  const rejectAll = () => {
    const policyData = JSON.stringify({
      necessary: true, // Always true
      analytics: false,
      marketing: false
    })
  
    // Store the policy data in the cookie
    cookiePolicy.value = encodeURIComponent(policyData) // URL encode the value
  
    consent.value.analytics = false
    consent.value.marketing = false
  
    isAccepted.value = true
    showDetail.value = false
  }
  
  // Show detailed popup
  const showDetailPopup = () => {
    showDetail.value = true
  }
  
  // Close the detailed popup
  const closeDetailPopup = () => {
    showDetail.value = false
  }
  </script>
  
  <style scoped>
  /* Slide-up transition for the initial popup */
  .slide-up-enter-active, .slide-up-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-up-enter, .slide-up-leave-to {
    transform: translateY(100%);
  }

  /* Styling for the popup background and buttons */
  .fixed {
    z-index: 9999;
    border-radius: 0;
  }

  button {
    font-size: 0.75rem; /* Small font size for buttons */
  }

  button.bg-blue-500:hover {
    background-color: #2563eb;
  }

  button.absolute.top-2.right-2 {
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background: transparent;
  }
</style>